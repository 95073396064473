import { clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs) {
  return twMerge(clsx(inputs));
}

/**
 * 沉睡一段时间
 * @param ms 延迟的毫秒数
 */
export const sleep = (ms = 1000) =>
  new Promise((resolve) => setTimeout(resolve, ms));
