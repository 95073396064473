import axios from "axios";

import { TOKEN_KEY } from "./constants";

export const isEternal = window.location.host === "eternal.wlswap.io";

export const axiosInstance = axios.create({
  baseURL: isEternal
    ? "https://eternal-api.wlswap.io"
    : "https://activecodetrading-api.wlswap.io",
  headers: {},
});

axiosInstance.interceptors.request.use((config) => {
  const token = localStorage.getItem(TOKEN_KEY);
  if (token) {
    config.headers.Authorization = `bearer ${token}`;
  }
  return config;
});

/**
 *
 * @param {string} url
 * @returns
 */
export const getFetcher = (url) =>
  axiosInstance.get(url).then((res) => res.data);

/**
 *
 * @param {string} url
 * @param {*} data
 * @returns
 */
export const postFetcher = (url, data) =>
  axiosInstance.post(url, data).then((res) => res.data);
